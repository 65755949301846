// hooks
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { Image } from "primereact/image";
import { Calendar } from "primereact/calendar";

// api related
import Api from "../../api/Api";

// utils
import NewTimeFormatter from "../../utils/NewTimeFormatter";
import TransactionStatus from "../../assets/data/product_shipping_status.json";
import priceFormatter from "../../utils/priceFormatter";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { TabPanel, TabView } from "primereact/tabview";
import { useDebounce } from "use-debounce";
import { pdf } from "@react-pdf/renderer";
import PackageLabel from "../../components/PDF/PackageLabel";

const TransactionTable = ({ transactionStatus, showRequestPickup = false, shippingStatus, showPrintStatus = false, printedValue = 0 }) => {
  // data limt will efect to page row and pagin
  let data_limit = 25;

  // state
  const [globalFilter, setGlobalFilter] = useState("");
  const [queryValue] = useDebounce(globalFilter, 1000);
  const [page, setPage] = React.useState(1);
  const [pageInfo, setPageInfo] = useState({});
  const [pageDatTable, setPageDataTable] = useState({ first: 0, rows: 10, page: 1 });
  const [expandedRows, setExpandedRows] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [filterDatesTemp, setFilterDatesTemp] = useState(null);
  const [filterDates, setFilterDates] = useState(null);
  const [printedStatus, setPrintedStatus] = useState(printedValue);

  // api calling
  const {
    data: transactionData,
    isLoading,
    refetch,
  } = useQuery(
    {
      queryKey: ["transaction", page, queryValue, filterDates, printedStatus, shippingStatus],
      queryFn: () => getTransactions(page),
      keepPreviousData: true,
    },
    { initialData: [] }
  );

  // query
  const { isLoading: requestPickupLoading, mutate: requestPickup } = useMutation(async (data) => await Api().post("/courier/multiple-pickup-request", data), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        setSelectItems([]);
        refetch();
        toast.success("Pick-Up Request Confirmed!", { duration: 4000 });
      } catch (error) {
        setSelectItems([]);
        refetch();
        toast.error(error.message || "something went wrong");
      }
    },
  });

  // functions
  const getTransactions = async () => {
    try {
      let query = {
        limit: data_limit,
        page: page,
        sort_at: "DESC",
        sort_by: "created_at",
        // mean only paid transaction can request pickup
      };

      if (transactionStatus !== undefined) {
        query.status = transactionStatus;
      }

      if (shippingStatus !== undefined) {
        query.status_shipping = shippingStatus;
      }

      if (queryValue) {
        query.query = queryValue;
      }

      //   on going
      if (shippingStatus !== 3) {
        query.printed_resi = printedStatus;
      }

      if (filterDates?.length) {
        let startDate = moment(filterDates[0]).format("YYYY-MM-DD");
        let endDate = filterDates[1] ? moment(filterDates[1]).format("YYYY-MM-DD") : startDate;

        query.start_date = startDate;
        query.end_date = endDate;
      }

      const res = await Api().get(`/transaction`, { params: query });
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      setPageInfo(res.data.pages);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const pageHandler = (data) => {
    setPageDataTable(data);
    setPage(data.page + 1);
  };

  // child components
  const rowExpansionTemplate = (rowData) => {
    return (
      <>
        <div className="p-3">
          <p>
            <span className="font-bold">PRODUCTS LIST</span>
          </p>
          <DataTable emptyMessage="No data." value={rowData.transaction_details}>
            <Column
              body={(data) => (
                <div style={{ maxWidth: "80px", maxHeight: "80px", overflow: "hidden" }}>
                  <Image preview imageClassName="object-contain w-full h-full" className="w-full h-full" src={data?.product?.images[0]?.url} alt="image" />
                </div>
              )}
              headerStyle={{ width: "150px", minWidth: "80px" }}
            ></Column>
            <Column field="product.name" header="Product Name"></Column>
            <Column
              field="variant_combinations"
              header="Variant"
              body={(d) => {
                let variantOne = d?.variant_combinations?.variant_detail_one?.name;
                let variantTwo = d?.variant_combinations?.variant_detail_two?.name;

                let displayTitle = [];

                if (variantOne) displayTitle.push(variantOne);
                if (variantTwo) displayTitle.push(variantTwo);

                return displayTitle.join(" - ") || "-";
              }}
            ></Column>
            <Column field="qty" header="Qty"></Column>
            <Column field="total_price" header="Price" body={(d) => priceFormatter(d.total_price)}></Column>
            <Column headerStyle={{ width: "4rem" }}></Column>
          </DataTable>
        </div>
      </>
    );
  };

  const allowExpansion = (rowData) => {
    return rowData.orders.length > 0;
  };

  const actionBodyTemplate = (rowData) => {
    const confirmRequestPickup = (event) => {
      confirmPopup({
        target: event.currentTarget,
        message: "Are you sure you want to proceed?",
        icon: "pi pi-exclamation-triangle",
        defaultFocus: "accept",
        accept: () =>
          requestPickup({
            transaction_ids: [rowData.id],
          }),
      });
    };

    return (
      <React.Fragment>
        <ConfirmPopup />
        <div className="actions">
          {showRequestPickup ? <Button icon="pi pi-car" label="Request Pickup" loading={requestPickupLoading} className="p-button-rounded p-button-primary" onClick={confirmRequestPickup} /> : null}{" "}
          {showPrintStatus ? <Button className="p-button-warning" onClick={() => printLabel([rowData])} icon="pi pi-id-card" label="Print" /> : null}
        </div>
      </React.Fragment>
    );
  };

  // reciev transaction ids
  const printLabel = async (transaction) => {
    try {
      const markAsPrinted = await Api().post("/transaction/print", {
        transaction_ids: transaction.map((item) => item._id),
      });

      if (markAsPrinted.data.status !== 200) {
        throw new Error(markAsPrinted.data.message);
      }

      let file_name = "";
      if (transaction.length > 1) {
        file_name = `WARDAH-Label`;
      } else {
        file_name = `WARDAH-${transaction[0].resi || transaction[0].transaction_id}`;
      }

      let blobPdf = await pdf(PackageLabel({ values: transaction })).toBlob();

      var fileURL = URL.createObjectURL(blobPdf);
      var a = document.createElement("a");
      a.href = fileURL;
      a.target = "_blank";
      a.download = file_name;
      a.click();

      refetch();
    } catch (error) {
      toast.error(error.message || "failed to print label");
    }
  };

  const header = () => {
    const confirmRequestPickup = (event) => {
      confirmPopup({
        target: event.currentTarget,
        message: "Are you sure you want to proceed?",
        icon: "pi pi-exclamation-triangle",
        defaultFocus: "accept",
        accept: () =>
          requestPickup({
            transaction_ids: selectItems.map((item) => item.id),
          }),
      });
    };

    return (
      <div className="">
        {/* <PDFViewer width={1000} height={1500}>
          <PackageLabel />
        </PDFViewer> */}
        <section className="flex justify-content-end align-items-center">
          {/* title */}
          {/* <div className="">
            <h5 className="m-0">Manage Request Pickup</h5>
          </div> */}

          {/* export */}
          <div className="flex flex-column md:flex-row mt-4 md:mt-0" style={{ gap: "14px" }}>
            <span className="block mt-auto p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
          </div>
        </section>

        <div className="flex justify-content-between align-items-end pt-4 mt-4" style={{ borderTop: "1px solid black", gap: "14px" }}>
          <div className="flex" style={{ gap: "14px" }}>
            {showRequestPickup ? <Button loading={requestPickupLoading} label={`Multiple Request Pickup (${selectItems.length})`} icon="pi pi-car" onClick={confirmRequestPickup} disabled={!selectItems.length} /> : null}
            {showPrintStatus ? <Button className="p-button-warning" loading={requestPickupLoading} label={`Multiple Print Label (${selectItems.length})`} icon="pi pi-id-card" onClick={() => printLabel(selectItems)} disabled={!selectItems.length} /> : null}
          </div>
          <div className="flex" style={{ gap: "14px" }}>
            <div className="flex align-items-end">
              <div className="flex flex-column">
                <label htmlFor="">Filter By Date</label>
                <Calendar showButtonBar className="mt-1" value={filterDatesTemp} onChange={(e) => setFilterDatesTemp(e.value)} selectionMode="range" placeholder="Select date range" />
              </div>
              <div className="ml-2">
                <Button disabled={!filterDatesTemp?.length} onClick={() => setFilterDates(filterDatesTemp)}>
                  Find
                </Button>
              </div>
            </div>
            <div className="mt-auto">
              {showPrintStatus ? (
                <div className="flex flex-column">
                  <label htmlFor="">Print Status</label>
                  <Dropdown
                    optionLabel="label"
                    optionValue="value"
                    value={printedStatus}
                    options={[
                      { label: "Print", value: 0 },
                      { label: "Printed", value: 1 },
                    ]}
                    className="w-full  mt-1"
                    onChange={(e) => {
                      setPrintedStatus(e.target.value);
                    }}
                    placeholder="Choose option"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // set page to 1 if gobal filter triggered
  useEffect(() => {
    if (globalFilter?.length) {
      setPage(1);
    }
    if (filterDates?.length) {
      setPage(1);
    }
    if (printedStatus) {
      setPage(1);
    }
  }, [globalFilter, printedStatus, filterDates]);

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          {/* <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar> */}
          <DataTable
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            loading={isLoading}
            value={transactionData}
            selection={selectItems}
            onSelectionChange={(e) => setSelectItems(e.value)}
            dataKey="id"
            paginator
            rows={data_limit}
            lazy
            onPage={pageHandler}
            first={pageDatTable.first}
            totalRecords={pageInfo.total_data}
            rowExpansionTemplate={rowExpansionTemplate}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            emptyMessage="No Data Found."
            header={header}
            responsiveLayout="scroll"
          >
            <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
            <Column expander={allowExpansion} style={{ width: "5rem" }} />
            <Column field="transaction_id" header="Invoice Number" headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
            <Column field="resi" header="AWB" body={(d) => (d.resi ? d.resi : "-")} headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
            <Column field="user.email" header="User email" body={(data) => data?.user?.email || data.guest_email} headerStyle={{ width: "auto", minWidth: "2rem" }}></Column>
            <Column field="user.email" header="Reciever" body={(data) => data?.shipping_address?.user_name || data?.guest_name} headerStyle={{ width: "auto", minWidth: "2rem" }}></Column>
            <Column field="created_at" header="Order Date" body={(d) => NewTimeFormatter(d.created_at)} headerStyle={{ minWidth: "4rem" }}></Column>
            <Column field="shipping_price" header="Shipping (Price)" body={(d) => priceFormatter(d.shipping_price)} headerStyle={{ minWidth: "5rem" }}></Column>
            <Column field="total_payment" header="Total Payment" body={(d) => priceFormatter(d.total_payment)} headerStyle={{ minWidth: "5rem" }}></Column>
            <Column field="status" className="capitalize" header="Status" body={(d) => TransactionStatus[d.status]} headerStyle={{ minWidth: "5rem" }}></Column>
            <Column header="ACTIONS" body={actionBodyTemplate} headerStyle={{ minWidth: "10rem" }}></Column>
          </DataTable>
        </div>
      </div>
    </>
  );
};

const RequestPickup = ({ permissions }) => {
  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card col-12 mx-auto">
            {/* <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar> */}
            <TabView>
              <TabPanel header="New Transaction">
                <TransactionTable showPrintStatus printedValue={0} permissions={permissions} transactionStatus={3} />
              </TabPanel>
              <TabPanel header="Printed Transaction">
                <TransactionTable showPrintStatus showRequestPickup printedValue={1} permissions={permissions} transactionStatus={3} />
              </TabPanel>
              <TabPanel header="On Going">
                <TransactionTable permissions={permissions} printedValue={1} shippingStatus={2} />
              </TabPanel>
              <TabPanel header="Delivered">
                <TransactionTable permissions={permissions} shippingStatus={3} />
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>

      {/* <DevTool control={updateForm.control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(RequestPickup, comparisonFn);
